<script setup>
import { ref } from 'vue'
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from "@headlessui/vue";

defineProps({
  componentId: {
    type: String,
    default: ""
  },
  tabs: {
    type: Array,
    default: () => ([])
  }
});
const selectedTab = ref(0)
const tabChanged = (index) => {
  selectedTab.value = index
}

</script>
<template>
  <div class="section-container">
    <TabGroup :selectedIndex="selectedTab" @change="tabChanged">
      <TabList class="flex flex-wrap space-x-1 rounded-xl bg-lime-900/10 p-1 mb-8">
        <Tab
          v-for="tab in tabs"
          :key="`tab-${tab.id}`"
          v-slot="{ selected }"
          as="template"
          class="flex-1 w-auto min-w-max"
        >
          <button
            :class="[
              'w-full rounded-lg py-2.5 font-medium leading-5 text-lime-700 px-4',
              'ring-white ring-opacity-70 focus:outline-none',
              selected
                ? 'bg-white shadow'
                : 'text-lime-100 hover:bg-white/30 hover:text-lime-500',
            ]"
          >
            {{ tab.label }}
          </button>
        </Tab>
      </TabList>
      <TabPanels>
        <TabPanel
          v-for="tab, index in tabs"
          :key="`tab-${tab.id}`"
          v-html="tab.content"
          v-show="index === selectedTab"
          static
        ></TabPanel>
      </TabPanels>
    </TabGroup>
  </div>
</template>
