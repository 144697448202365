import { createApp } from 'vue'
import TabApp from './components/tabs/tabs-container.vue'
import VueSanitize from 'vue-3-sanitize'

// check all tab containers declared serverside
if (typeof window.containerTabs === 'object') {
  try {
    const filteredTabs = window.containerTabs.filter((el) => {
      return !!el?.id
    })
    for (const tabInstanceDefinition of filteredTabs) {
      const componentId = tabInstanceDefinition.id
      const tabs = tabInstanceDefinition.tabs
      const tabApp = createApp(TabApp, { componentId, tabs })
      tabApp.use(VueSanitize)
      tabApp.mount('#' + componentId)
      console.log('create app tab-app ' + componentId)
    }
  } catch (e) {
    console.log(e)
  }
}
